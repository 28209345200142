* {
  text-align: left;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 50%;
  margin: 0 auto;
}

h1 {
  font-size: 4.2rem;
  line-height: 100%;

}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.4rem;
}

label {
  font-size: 1.1rem;
}

.prev-workouts {
  font-size: 1.1rem;
}

.btn-plusminus {
  background-color: rgba(242, 242, 242, 0.696);
}

.user-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: stretch;
  width: auto;
}

.user-form input {
  height: 30px;
  border-radius: 4px;
}

.auth-btn {
  align-self: flex-start;
  padding: 0 50px;
}

.increment-decrement {
  display: flex;
  gap: 5px;
}

a {
  text-decoration: none;
  color: grey;
  font-size: 1rem;
}

p a {
  font-size: inherit;
  color: inherit;
  padding: 0;
}

.privacy-policy {
  padding: 10%;
  line-height: 1.6;
}

.app-wrapper {
  width: 90%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

nav {
  padding: 2% 5%;
}

footer {
  padding-top: 10%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}